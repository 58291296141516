<template>
  <div class='sound_cloning'>
    <div class="sound_cloning_main">
      <div class="sound_cloning_l">
        <div class="form">
          <div class="form-label"><span>*</span>模型名称</div>
          <div class="form-value">
            <div class="input">
              <el-input v-model="name" placeholder="请输入模型名称"></el-input>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-label">头像</div>
          <div class="form-value">
            <div class="head" @click="editOpen">
              <img :src="avatar" alt="">
              <div class="mask">编辑头像</div>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-label">标签</div>
          <div class="form-value">
            <div class="input">
              <el-input type="textarea" placeholder="请输入模型标签，多个以｜分割" v-model="label" rows="4">
              </el-input>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-label"></div>
          <div class="form-value">
            <div class="sound_list" v-for="(item,index) in soundList" :key="index">
              <div class="sound_list_main">
                <div class="name">
                  <img src="@/assets/images/workben/zip.png" alt="">
                  <span>{{item.name}}</span>
                </div>
                <div class="check">
                  <img src="@/assets/images/workben/check.png" alt="">
                </div>
              </div>
              <img class="sound_del" @click="del(index)" src="@/assets/images/workben/del.png" alt="">
            </div>
          </div>
        </div>
        <div class="form">
          <div class="form-label"></div>
          <div class="form-value">
            <p class="tips">上传录音中语料时长必须大于20分钟，大小不超过500M，请继续上传</p>
          </div>
        </div>
        <div class="form">
          <div class="form-label"></div>
          <div class="form-value">
            <upload-sound @uploadSuccess="uploadSuccess"></upload-sound>
          </div>
        </div>
      </div>
      <div class="sound_cloning_r">
        <div class="tips_main_tit">
          <img src="@/assets/images/workben/warn.png" alt="">
          <span>上传提示</span>
        </div>
        <div class="tips_li" v-for="item,index in warnList" :key="index">{{ item }}</div>
        <div class="tips_main_tit mt30">
          <img src="@/assets/images/workben/warning.png" alt="">
          <span>注意事项</span>
        </div>
        <div class="ul">
          <div class="li" v-for="item,index in tipsList" :key="index">
            <img :src="item.img" alt="">
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="sound_bottom">
      <div class="sound_bottom_l">
        <el-checkbox v-model="checked"></el-checkbox>
        <p>
          我已阅读并同意
          <span>《声音克隆协议》</span>
        </p>
      </div>
      <div class="sound_bottom_r">
        <span>总计：{{price}}钻</span>
        <img src="@/assets/images/person/accountManage/zuan.png" alt="">
        <el-button size="small" type="primary" :loading="loading" @click="confirm">结 算</el-button>
      </div>
    </div>
    <avatar-edit ref="edit" @editAvatar="editAvatar"></avatar-edit>
    <!-- 支付 -->
    <pay-diamond ref='dialogdiamond' @renovate='renovateBtn' @paySuccess='paySuccessBtn'></pay-diamond>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex"
  import PayDiamond from '@/components/pay_page/pay_diamond.vue' //支付
  import UploadSound from '../components/upload_sound/index.vue';
  import AvatarEdit from '../components/avatar_edit/index.vue';
  export default {
    components: {
      PayDiamond,
      AvatarEdit,
      UploadSound
    },
    props: {},
    data() {
      return {
        checked: false,
        loading: false,
        name: '',
        label: '',
        warnList: [
          '1. 有效普通话音频总时长不少于20分钟（方言不少于30分钟），不能包含英文或其他国家语言；',
          '2. 做到吐字清晰，不漏字、不叠字、不错字，句子与句子之间停顿超过0.5秒；',
          '3. 支持使用单条或者多条音频文件；',
          '4. 所有语料需为同一个人声音；',
        ],
        tipsList: [{
            img: require('@/assets/images/workben/z11.png'),
            text: '环境保持安静',
          },
          {
            img: require('@/assets/images/workben/z12.png'),
            text: '普通话录音',
          },
          {
            img: require('@/assets/images/workben/z13.png'),
            text: '推荐wav,fiv无损音质',
          },
          {
            img: require('@/assets/images/workben/z14.png'),
            text: '推荐48k采样率',
          },
        ],
        avatar: 'https://yc-digital.oss-cn-shenzhen.aliyuncs.com/icon/avatar/t1.png',
        soundList: [],
        price: '15000',
      }
    },
    created() {
      this.getPrice()
    },
    computed: {
      ...mapState({
        userInfo: (state) => state.userInfo.user_info,
      }),
    },
    methods: {
      // 支付关闭刷新
      renovateBtn() {
        this.getPrice()
      },
      // 支付成功
      paySuccessBtn() {
        var that = this
        setTimeout(function() {
          that.$store.dispatch('tabsArr/delTabList')
        }, 2000)
      },
      confirm() {
        if (!this.checked) {
          this.$message.error("请阅读并同意声音克隆协议");
          return
        }
        if (this.name == '') {
          this.$message.error("请输入模特名称");
          return
        }
        if (this.soundList.length == 0) {
          this.$message.error("请上传语料");
          return
        }
        // let arr = [];
        // this.soundList.map(item => {
        //     arr.push(item.short_url)
        // })
        let obj = {
          name: this.name,
          avatar: this.avatar,
          label: this.label,
          audio_file: this.soundList,
          order_source: 1
        }
        this.loading = true;
        this.$workbenApi.getAudioOrder(obj).then(res => {
          this.loading = false;
          if (res.code == 1000) {
            let parmas = {
              diamond: this.userInfo.diamond,
              price: this.price,
              order_num: res.result.order_num,
              name: this.name,
            }
            this.$refs.dialogdiamond.openDialogBtn(parmas, 5)
          } else {
            this.$errMsg(res.message)
          }
        })
      },
      getPrice() {
        this.$workbenApi.audioPrice().then(res => {
          if (res.code == 1000) {
            this.price = res.result.audio_price
          }
        })
      },
      // 上传语料
      uploadSuccess(url, name) {
        this.soundList.push({
          name: name,
          url: url
        })
      },
      // 删除语料
      del(index) {
        this.soundList.splice(index, 1)
      },
      editAvatar(url) {
        this.avatar = url
      },
      editOpen() {
        this.$refs.edit.open()
      },

    },
  }
</script>

<style lang='scss' scoped>
  .sound_cloning {
    .sound_cloning_main {
      width: 100%;
      height: 650px;
      background: #ffffff;
      border-radius: 10px;
      padding: 30px 20px;
      display: flex;

      .sound_cloning_l {
        width: 650px;

        .form {
          display: flex;
          margin-bottom: 20px;

          .form-label {
            width: 68px;
            margin-right: 10px;
            text-align: right;
            color: #333333;
            font-size: 14px;
            line-height: 40px;

            span {
              color: #e00c25;
            }
          }

          .form-value {
            .input {
              width: 300px;
            }

            .tips {
              font-size: 14px;
              color: #ff495e;
            }

            .head {
              width: 88px;
              height: 88px;
              border-radius: 50%;
              overflow: hidden;
              position: relative;
              cursor: pointer;

              img {
                width: 88px;
                height: 88px;
              }

              .mask {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
                display: none;
                font-size: 14px;
                color: #ffffff;
                line-height: 88px;
                text-align: center;
              }
            }

            .head:hover {
              .mask {
                display: block;
              }
            }

            .sound_list {
              display: flex;
              align-items: center;

              .sound_list_main {
                width: 300px;
                height: 48px;
                background: #f8f8f8;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px 0 14px;

                .name {
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  color: #666666;

                  img {
                    width: 14px;
                    height: 14px;
                    margin-right: 6px;
                  }
                }

                .check {
                  width: 14px;
                  height: 14px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .sound_del {
                width: 48px;
                height: 48px;
                margin-left: 6px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .sound_cloning_r {
        width: 755px;
        height: 340px;
        background: #f5f7fa;
        border-radius: 4px;
        padding: 30px;

        .tips_main_tit {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }

          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-left: 6px;
          }
        }

        .tips_li {
          font-size: 12px;
          color: #9097a5;
          line-height: 20px;
          padding-top: 10px;
        }

        .ul {
          display: flex;
          align-items: center;
          margin-top: 16px;

          .li {
            margin-right: 30px;

            img {
              width: 60px;
              height: 38px;
              margin: 0 auto;
            }

            p {
              font-size: 14px;
              color: #666666;
              line-height: 20px;
              padding-top: 4px;
              text-align: center;
            }
          }
        }
      }
    }

    .sound_bottom {
      width: 100%;
      height: 80px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 30px;
      margin-top: 20px;

      .sound_bottom_l {
        display: flex;
        align-items: center;

        p {
          padding-left: 10px;
          font-size: 14px;
          color: #666666;

          span {
            cursor: pointer;
            color: #2e4bf2;
          }
        }
      }

      .sound_bottom_r {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666666;

        img {
          margin-left: 6px;
          margin-right: 20px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .mt30 {
    margin-top: 30px;
  }
</style>
